import { useEffect } from 'react'

import useAuth from '../contexts/auth'

export default function loginPage() {
  const { login } = useAuth()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const returnTo = new URLSearchParams(window.location.search).get(
        'returnTo',
      )

      // FIXME: strictNullCheck temporary fix
      // @ts-expect-error TS(2721): Cannot invoke an object which is possibly 'null'.
      void login(returnTo)
    }
  }, [typeof window])

  return null
}
